"use client";
import { useState } from "react";
import * as Yup from "yup";
import { CiUser } from "react-icons/ci";
import { MdOutlineMail } from "react-icons/md";
import { BsChatSquare } from "react-icons/bs";
import { toast } from "react-hot-toast"; // Importing toast
import { FiPhone } from "react-icons/fi"; // Make sure to import this icon

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Вашето име е задължително"),
  email: Yup.string()
    .email("Невалиден имейл адрес")
    .required("Имейл адрес е задължителен"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Телефонният номер трябва да съдържа само цифри")
    .required("Мобилен телефон е задължителен"),
  message: Yup.string().required("Съобщението е задължително"),
});

export default function CTA() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [currentError, setCurrentError] = useState<string | null>(null); // State for current error

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear current error when the user types
    if (currentError) {
      setCurrentError(null);
    }
  };

  const validateForm = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setCurrentError(null); // Reset the current error
      return true;
    } catch (err: any) {
      // Show only the first error
      if (err.inner.length > 0) {
        setCurrentError(err.inner[0].message);
        toast.error(err.inner[0].message); // Show error message in toast
      }
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = await validateForm();

    if (isValid) {
      try {
        const responseAdmin = await fetch("/api/send-email", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subject: "Изпращане на формуляр за контакт",
            to: process.env.NEXT_PUBLIC_ADMIN_EMAIL,
            html: `
                  <div class="container">
                     <h1>Изпращане на нов формуляр за контакт</h1>
                     <div class="contact-info">
                        <p><span>Име:</span> ${formData.name}</p>
                        <p><span>Имейл:</span> ${formData.email}</p>
                        <p><span>Телефон:</span> ${formData.phone}</p>
                      </div>
                      <div class="message">
                        <h2>Съобщение</h2>
                        <p>${formData.message}</p>
                      </div>
                    </div>            
                    `,
          }),
        });

        const responseUser = await fetch("/api/send-email", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subject: "Заявката беше приета",
            to: formData.email,
            html: `
                  <div class="container">
                     <h1>БЗаявката беше приета</h1>
                     <div class="contact-info">
                        <p><span>Име:</span> ${formData.name}</p>
                        <p><span>Имейл:</span> ${formData.email}</p>
                        <p><span>Телефон:</span> ${formData.phone}</p>
                      </div>
                      <div class="message">
                        <h2>Съобщение</h2>
                        <p>${formData.message}</p>
                      </div>
                    </div>            
                    `,
          }),
        });

        toast.success("Заявката е изпратена успешно."); // Use toast for success
        setFormData({ name: "", email: "", phone: "", message: "" }); // Reset form
      } catch (error: any) {
        console.error("Error:", error);
        toast.error(error?.message); // Use toast for error
      }
    }
  };

  return (
    <div className="cta">
      <div className="cta__inner bg-[#F0F0F0] p-12">
        <div className="shell">
          <header className="cta__head">
            <h2 className="font-bold">Имаш въпрос?</h2>
            <h3>Свържи се с нас!</h3>
          </header>

          <form onSubmit={handleSubmit} className="form-cta">
            <div className="grid grid-cols-1 md:grid-cols-3 md:mx-10 gap-4">
              <div className="form__input relative col-span-1">
                <div className="absolute top-1/2 left-3 transform -translate-y-1/2">
                  <CiUser size={24} color="#A2B4D3" />
                </div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Вашето име"
                  className={`py-2 pl-10 w-full border rounded ${
                    currentError && !formData.name ? "border-red-500" : ""
                  }`}
                />
              </div>

              <div className="relative form__input">
                <div className="absolute top-1/2 left-3 transform -translate-y-1/2">
                  <MdOutlineMail color="#A2B4D3" size={24} />
                </div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Вашия имейл адрес"
                  className={`pl-10 py-2 w-full ${
                    currentError && !formData.email ? "border-red-500" : ""
                  }`}
                />
              </div>

              <div className="form__input relative">
                <div className="absolute top-1/2 left-3 transform -translate-y-1/2">
                  <FiPhone color="#A2B4D3" size={24} />
                </div>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Вашия мобилен телефон"
                  className={`pl-10 py-2 w-full ${
                    currentError && !formData.phone ? "border-red-500" : ""
                  }`}
                />
              </div>

              <div className="form__input relative mt-2 col-span-full">
                <div className="absolute top-[24px] left-3 transform -translate-y-1/2">
                  <BsChatSquare size={24} color="#A2B4D3" />
                </div>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder="Вашето съобщение"
                  className={`pl-10 py-2 w-full ${
                    currentError && !formData.message ? "border-red-500" : ""
                  }`}
                ></textarea>
              </div>
            </div>

            {/* Show current error message here */}

            <div className="form__actions mt-2">
              <button
                type="submit"
                className="btn bg-white text-black hover:bg-black hover:text-white"
              >
                Изпрати съобщение
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
